<template>
  <v-dialog
    v-model="file_upload"
    max-width="600px"
    @click:outside="onClose"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("upload") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-file-input
              show-size
              @change="selectFile"
              label="File input"
            ></v-file-input>
          </v-row>
          <v-row justify="center">
            <v-progress-circular
              v-show="uploading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="upload()">
          {{ $t("upload") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="onClose()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    file_upload: false,
    schedule_id: null,
  },
  data: (vm) => ({
    currentFile: null,
    uploading: false,
    fileName: null,
  }),
  computed: {
    ...mapState(["token"]),
  },

  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
      if (file != null) {
        this.fileName = file.name;
        console.log("name " + this.fileName);
      }
    },
    reset() {
      this.currentFile = null;
      this.uploading = false;
      this.fileName = null;
    },
    upload() {
      const currentObj = this;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      const url = process.env.VUE_APP_SERVER_URL + "/backend/upload/";

      console.log("url " + url);

      let formData = new FormData();
      formData.append("schedule_id", this.schedule_id);
      formData.append("title", this.fileName);
      formData.append("document", this.currentFile);
      formData.append("user", 1);
      this.uploading = true;
      this.axios
        .post(url, formData, config)
        .then(function (response) {
          console.log("upload ok");
          console.log(response.data);
          // TODO show 上傳成功
          currentObj.reset();
          currentObj.onClose();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>