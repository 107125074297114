<template>
  <v-container id="mission-tables" fluid tag="section">
    <ConfirmDialog
      :isShow="confirmData.isConfirm"
      :title="confirmData.confirm_title"
      :message="confirmData.confirm_msg"
      :selectItem="confirmData.selectItem"
      :selectAction="confirmData.selectAction"
      v-on:onClose="onClose"
      v-on:onConfirm="onConfirm"
    ></ConfirmDialog>
    <v-row>
      <v-col v-if="!read_only">
        <MissionRestartLbkey></MissionRestartLbkey>
      </v-col>
      <v-col class="text-right" v-if="!read_only">
        <!-- [begin] dialog for search lbkey and add mission -->
        <MissionCreateDialog :create_form="create_form"></MissionCreateDialog>
        <!--[end] dialog for search lbkey and add mission -->
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      title="mission"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        show-expand
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-btn-toggle v-model="filter_toggle" color="primary">
            <!-- Filter -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined @click="doFilter('0')" v-bind="attrs" v-on="on">
                  <v-icon>mdi-tools</v-icon>
                </v-btn>
              </template>
              <span>Running</span>
            </v-tooltip>
            <!-- All -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined @click="doFilter('1')" v-bind="attrs" v-on="on">
                  <v-icon>mdi-collapse-all-outline</v-icon>
                </v-btn>
              </template>
              <span>All</span>
            </v-tooltip>
            <!-- List Only -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  @click="doActionFilter(action_type_enum.List)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>List</span>
            </v-tooltip>
            <!-- TP -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  @click="doActionFilter(action_type_enum.Transcript)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-script-outline</v-icon>
                </v-btn>
              </template>
              <span>Transcript</span>
            </v-tooltip>
            <!-- Change -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  @click="doActionFilter(action_type_enum.Mixed)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pot-mix-outline</v-icon>
                </v-btn>
              </template>
              <span>Mixed</span>
            </v-tooltip>
          </v-btn-toggle>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="12">
            <task :mission_id="item.id"></task>
          </td>
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | getText(status_items) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-edit-dialog @save="changeMissionAction(item)" large>
            {{ item.action | getText(lb_actions) | Translate }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.action"
                  :label="$t('action')"
                  :items="lb_actions"
                  :item-text="(item) => $t(item.text)"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.change_type="{ item }">{{
          item.change_type | getText(change_types) | Translate
        }}</template>
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.cdr="{ item }">{{ item.cdr }}</template>
        <template v-slot:item.done_rate="{ item }">
          <span :class="isCompleted(item)">
            {{ item.task_done_num }}/{{ item.task_num }} ({{
              parseFloat(
                (item.task_done_num / item.task_num || 0) * 100
              ).toFixed(2) + "%"
            }})
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="inPool(item)"
                >mdi-pool</v-icon
              >
            </template>
            <span>{{ $t("in-pool") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template
              v-slot:activator="{ on }"
              v-if="
                item.action == action_type_enum.List && item.change_type == 1
              "
            >
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="forceCopOwnerDiff(item)"
                >mdi-application-edit</v-icon
              >
            </template>
            <span>{{ $t("cop-owner-diff") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template
              v-slot:activator="{ on }"
              v-if="
                (item.action == action_type_enum.List ||
                  item.action == action_type_enum.Mixed) &&
                item.change_type == 2
              "
            >
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="forceCopCreditorDiff(item)"
                >mdi-access-point-network</v-icon
              >
            </template>
            <span>{{ $t("cop-creditor-diff") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="20"
        circle
      ></v-pagination>
    </base-material-card>
  </v-container>
</template>
<script>
import Task from "./Task";
import MissionCreateDialog from "../dialogs/MissionCreateDialog";
import MissionRestartLbkey from "../dialogs/MissionRestartLbkey";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import CommonUtil from "@/utils/commonutil.js";

import { mapState } from "vuex";
import {
  status_items,
  // lbtype_option,
  mission_action,
  change_types,
  interval,
  action_type_enum,
} from "@/definition.js";

export default {
  data: (vm) => ({
    filter_toggle: 0,
    filter_status: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    default_system: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    create_form: false,
    action: null,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("cdr"),
        value: "cdr",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action"),
        value: "action",
      },
      {
        sortable: false,
        text: vm.$i18n.t("change_type"),
        value: "change_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("done_rate"),
        value: "done_rate",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbtype"),
        value: "lbtype",
      },
      {
        sortable: false,
        text: vm.$i18n.t("task_num"),
        value: "task_num",
      },
      {
        sortable: true,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: true,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },

      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],

    interval: interval,
    status_items: status_items,
    lb_actions: mission_action,
    change_types: change_types,
    action_type_enum: action_type_enum,
    mission_restart_lbkey: false,
    confirmData: {
      isConfirm: false,
      confirm_title: "啟動新任務",
      confirm_msg: "",
      selectItem: null,
      selectAction: 0,
    },
  }),
  components: {
    Task,
    MissionCreateDialog,
    MissionRestartLbkey,
    ConfirmDialog,
  },
  props: { schedule_id: null, read_only: false },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    isCompleted(item) {
      if (
        (item.task_done_num == item.task_num && item.task_num > 0) ||
        item.status == 9
      ) {
        return "blue";
      }
      return "";
    },
    getMission() {
      // console.log("mission readonly " + this.read_only);
      const currentObj = this;
      currentObj.loading = true;
      const { sortBy, sortDesc } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/missions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: this.page,
          page_size: this.itemsPerPage,
        },
      };
      if (sortBy.length > 0) {
        const sortParas = CommonUtil.getSortParas(sortBy, sortDesc);
        config.params.ordering = sortParas;
      }
      if (!this.$common_util.isEmpty(this.schedule_id)) {
        config.params["schedule__id"] = this.schedule_id;
      }
      if (this.filter_status == 0) {
        config.params["status__lt"] = 9;
      }
      if (this.action != null) {
        config.params["action"] = this.action;
      }

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.items = response.data["results"];
          currentObj.totalItems = response.data["count"];
          // console.log(currentObj.items);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    inPool(item) {
      if (item.status == 0) {
        this.confirmData.selectItem = item;
        this.confirmData.selectAction = item.mission_action;
        this.confirmData.confirm_msg = `Mission: ${item.task_num}任務進入Pool?`;
        this.confirmData.isConfirm = true;
      } else {
        console.log("Already in pool");
      }
    },
    forceOwnerDiff(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.OwnerDiff;
      this.confirmData.confirm_msg = `Mission: ${item.task_num}任務使用owner diff?`;
      this.confirmData.isConfirm = true;
    },
    forceCopOwnerDiff(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.CopOwnerDiff;
      this.confirmData.confirm_msg = `Mission: ${item.task_num}任務使用COP owner diff?`;
      this.confirmData.isConfirm = true;
    },
    forceCopCreditorDiff(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = action_type_enum.CopCreditorDiff;
      this.confirmData.confirm_msg = `Mission: ${item.task_num}任務使用COP Creditor diff?`;
      this.confirmData.isConfirm = true;
    },
    onClose() {
      console.log("close the dialog");
      this.mission_restart_lbkey = false;
      this.confirmData.isConfirm = false;
      this.confirmData.confirm_msg = "";
      this.confirmData.selectAction = 0;
      this.confirmData.selectItem = null;
    },
    // Confirm
    onConfirm(data) {
      let _item = data.item;
      let action = data.action;
      console.log("item ", _item, " action:", action);
      switch (action) {
        case action_type_enum.OwnerDiff:
          this.doForceOwnerDiff(_item.id, action);
          break;
        case action_type_enum.CopOwnerDiff:
          this.doForceOwnerDiff(_item.id, action);
          break;
        case action_type_enum.CopCreditorDiff:
          this.doForceOwnerDiff(_item.id, action);
          break;
        default:
          this.postEnqueue(_item.id);
          break;
      }

      this.onClose();
    },
    postEnqueue(mission_id) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/missions/${mission_id}/enqueueByMissionID/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      if (this.items.length > 0) {
        let jsonData = {
          mission: mission_id,
        };
        this.axios
          .post(url, jsonData, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.getMission();
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }
    },
    doForceOwnerDiff(mission_id, action) {
      const currentObj = this;
      let url = null;
      if (action == action_type_enum.OwnerDiff) {
        url = `${process.env.VUE_APP_SERVER_URL}/backend/missions/${mission_id}/ownerdiff/`;
      } else if (action == action_type_enum.CopOwnerDiff) {
        url = `${process.env.VUE_APP_SERVER_URL}/backend/missions/${mission_id}/packCopOwnerRegnoDiff/`;
      } else if (action == action_type_enum.CopCreditorDiff) {
        url = `${process.env.VUE_APP_SERVER_URL}/backend/missions/${mission_id}/packCopCreditorRegnoDiff/`;
      }
      if (url == null) {
        console.log("Fail to force owner diff");
        return;
      }
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      if (this.items.length > 0) {
        // let lbkey = this.items[0].lbkey;
        console.log("mission " + mission_id);
        let jsonData = {
          mission: mission_id,
        };
        this.axios
          .post(url, jsonData, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.getMission();
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }
    },

    doFilter(status) {
      this.page = 1;
      this.action = null;
      this.filter_status = status;
      this.getMission();
    },
    doActionFilter(action) {
      this.page = 1;
      this.filter_status = 0;
      this.action = action;
      this.getMission();
    },
    changeMissionAction(item) {
      console.log(`change mission ${item.id} action ${item.action}`);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/missions/${item.id}/change_action/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      if (this.items.length > 0) {
        let jsonData = {
          mission: item.id,
          action: item.action,
        };
        this.axios
          .post(url, jsonData, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.getMission();
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getMission();
      },
    },
  },
};
</script>
