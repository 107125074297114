 <template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
    @keydown.esc="onClose()"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dense class="mx-1" v-bind="attrs" v-on="on">
        檢查
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> 檢查排程狀態 </v-card-title>
      <v-card-text>檢查排程與任務狀態</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="checkStatus()"> 檢查 </v-btn>
        <v-btn color="green darken-1" text @click="onClose()"> 取消 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    dialog: false,
  }),
  props: { customer_mission_id: null, isHideExpand: false },

  components: {},
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    checkStatus() {
      console.log("check all schedule.");
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/schedules/checkScheduleStatus/";
      let jsonData = {};
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.dialog = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onClose() {
      this.dialog = false;
    },
  },
};
</script>
