<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1200"
    @keydown.esc="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="blue" dense class="mx-1" v-bind="attrs" v-on="on">
        上傳
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> 上傳檔案 </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              truncate-length="30"
              @change="selectFile"
            ></v-file-input>
          </v-col>
          <v-col>
            <v-radio-group v-model="schedule_type" row>
              <v-radio
                v-for="option in schedule_types"
                :key="option.text"
                :label="option.text"
                :value="option.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" @click="createListSchedule"> 上傳 </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import Papa from "papaparse";
export default {
  data: (vm) => ({
    dialog: false,
    uploadFile: null,
    schedule_type: 0,
    schedule_types: [
      {
        text: "列表",
        value: 0,
      },
      {
        text: "謄本",
        value: 1,
      },
    ],
  }),
  props: { customer_mission_id: null, isHideExpand: false },

  components: {},
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    selectFile(file) {
      this.uploadFile = file;
    },
    parseFile() {
      const currentObj = this;
      Papa.parse(this.uploadFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          console.log("data", this.content.data);
          // get lbkeys and regnos
          let lbkeys_array = [];
          let regnos_array = [];
          currentObj.content.data.forEach((item) => {
            lbkeys_array.push(item.lbkey);
            console.log();
            if (item.regnos) {
              regnos_array.push(item.regnos);
            }
          });
          let url = null;
          // transcript type
          let lbkeys_array_str = lbkeys_array.join(",");
          let jsonData = { lbtype: "L", lbkeys: lbkeys_array_str };
          if (this.schedule_type == 1) {
            url = `${process.env.VUE_APP_SERVER_URL}/public_internal/transcripts/`;
            if (regnos_array.length > 0) {
              let regnos_str = regnos_array.join(";");
              jsonData["regnos"] = regnos_str;
            }
          } else {
            // list type
            url = `${process.env.VUE_APP_SERVER_URL}/public_internal/lists/`;
          }
          currentObj.postScheduleAPI(url, jsonData);
          console.log("lbkey", lbkeys_array);
          console.log("regnos", regnos_array);
        }.bind(this),
      });
    },
    createListSchedule() {
      console.log(
        "uploadFile.",
        this.uploadFile,
        " schedule_types:",
        this.schedule_type
      );
      if (this.uploadFile == null) {
        return;
      }

      this.parseFile();
    },
    postScheduleAPI(url, jsonData) {
      const currentObj = this;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      // console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.dialog = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.$emit("update");
        });
    },
  },
};
</script>
