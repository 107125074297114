<template>
  <v-container id="task-tables" fluid tag="section">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      multi-sort
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <template v-slot:header>
        <tr>
          <td>
            <v-text-field
              v-model="filterItem.lbkey"
              :label="$t('lbkey')"
              outlined
              dense
              clearable
              @change="onChange"
            ></v-text-field>
          </td>
          <td></td>

          <td>
            <v-select
              v-model="filterItem.status"
              :label="$t('status')"
              :items="status_items"
              outlined
              dense
              clearable
              @change="onChange"
            ></v-select>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:item.regnos="{ item }">{{
        getPartial(item.regnos)
      }}</template>

      <template v-slot:item.status="{ item }">{{
        item.status | getText(status_items)
      }}</template>
      <template v-slot:item.update_time="{ item }">{{
        item.update_time | moment("YYYY-MM-DD HH:mm:ss")
      }}</template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="20"
    ></v-pagination>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { status_items } from "@/definition.js";
export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    selected: [],
    info_dialog: false,
    dialog_form: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    filterItem: {
      city: null,
      district: null,
      region: null,
      lbkey: null,
      action: null,
      status: null,
      create_time: {},
    },

    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
      },
      {
        sortable: false,
        text: vm.$i18n.t("regnos"),
        value: "regnos",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commit_id"),
        value: "commit_id",
      },
    ],
    status_items: status_items,
  }),
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  props: ["mission_id"],
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("pool");
    },
  },
  methods: {
    getPartial(data) {
      if (data != null) {
        let data_array = data.split(",");
        return data.substring(0, 20) + ".....(" + data_array.length + ")";
      }
      return data;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/tasks/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          mission: this.mission_id,
        },
      };

      if (!this.$common_util.isEmpty(this.filterItem.lbkey)) {
        config.params["lbkey__startswith"] = this.filterItem.lbkey;
      }
      if (!this.$common_util.isEmpty(this.filterItem.action)) {
        config.params["action"] = this.filterItem.action;
      }
      if (!this.$common_util.isEmpty(this.filterItem.status)) {
        config.params["status"] = this.filterItem.status;
      }

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    postApi() {},
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createItem() {
      this.action = "create";
    },
    close() {
      this.dialog_form = false;
    },
    save() {
      this.postEnqueue();
      this.close();
    },
    onChange() {
      this.getApi();
    },
  },
  mounted() {
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
};
</script>
