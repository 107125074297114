<template>
  <v-dialog
    v-model="dialog_show"
    max-width="600px"
    @click:outside="onClose"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Schedule Move</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                label="From"
                v-model="origin"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="To" v-model="dest"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" dark @click="moveSchedule()">
                {{ $t("move") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    dialog_show: false,
    origin: 0,
  },
  data: (vm) => ({
    dest: 0,
  }),
  computed: {
    ...mapState(["token"]),
  },

  methods: {
    getApi() {
      // const currentObj = this;
      // const url = process.env.VUE_APP_SERVER_URL + this.mongo_url + "id_only/";
      // let config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: "JWT " + this.token,
      //   },
      //   params: {
      //     lbkey: this.lbkey,
      //   },
      // };
      // this.axios
      //   .get(url, config)
      //   .then(function (response) {
      //     console.log(response.data);
      //     currentObj.loading = false;
      //     // currentObj.setResult(response.data);
      //     currentObj.transcript = response.data;
      //     let o_data = response.data["o_data"];
      //     for (let item in o_data) {
      //       currentObj.o_data_items.push(item);
      //       currentObj.o_data_value.push(o_data[item]);
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     console.log(error.response);
      //   });
    },
    onClose() {
      this.reset();
      this.$emit("onClose");
    },

    reset() {
      this.dest = 0;
    },
    moveSchedule() {
      console.log("move to ", this.dest);
    },
  },
};
</script>