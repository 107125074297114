<template>
  <v-dialog v-model="mission_restart_lbkey" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="secondary" dark class="mb-2" v-on="on">
        <v-icon left>mdi-account-plus</v-icon>
        {{ $t("restart-lbkey-mission") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("restart-lbkey-mission") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field label="lbkey" disabled />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="lbkey"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="regnos" disabled />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="regnos"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="blue darken-1" @click="postApi">
          {{ $t("submit") }}
        </v-btn>
        <v-btn color="blue darken-1" @click="onClose">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  props: {},
  data: (vm) => ({
    mission_restart_lbkey: false,
    lbkey: null,
    regnos: null,
  }),
  computed: {
    ...mapState(["token"]),
  },

  methods: {
    postApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/missions/restartLbkey/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      const jsonData = {
        lbkey: this.lbkey,
        regnos: this.regnos,
      };
      console.log("jsonData " + JSON.stringify(jsonData));
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.onClose()
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onClose() {
      console.log("close it");
      this.mission_restart_lbkey = false;
      // this.$emit("onClose");
    },
  },
};
</script>