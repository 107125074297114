<template>
        <!-- [begin] dialog for search lbkey and add schedule -->
        <v-dialog v-model="dialog_form" max-width="60%" @click:outside="close">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="createItem"
            >
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("schedule-create") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="2">
                    <v-select
                      :label="$t('city')"
                      :items="city_info"
                      v-model="editedItem.car.city"
                      v-on:change="getDistrictsApi"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('district')"
                      :items="district_info"
                      v-model="editedItem.car.district"
                      :item-text="(item) => item.name + '(' + item.code + ')'"
                      item-value="code"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('type')"
                      :items="type_items"
                      v-model="editedItem.type"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      :label="$t('thread_count')"
                      v-model="editedItem.thread_count"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :label="$t('action')"
                      :items="spider_actions"
                      v-model="editedItem.action"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('target')"
                      :items="change_types"
                      v-model="editedItem.target"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('lbtype')"
                      :items="lbtype_option"
                      v-model="editedItem.car.lbtype"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-if="action != 'view'">
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="save">
                {{ $t("create_schedule") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--[end] dialog for search lbkey and add schedule -->
      </v-col>
</template>
<script>
import { mapState } from "vuex";
import { city_info,action_type ,change_types,lbtype_option} from "@/definition.js";
export default {
  data: (vm) => ({
    default_system: false,

    editedItem: {
      car: {
        city: "",
        district: "",
        interval: 7,
        lbtype:"L",
      },
      type: 0,
      thread_count: 3,
      action:0,
      target:2,
    },
    defaultItem: {
      car: {
        city: "",
        district: "",
        interval: 7,
        lbtype:"L",
      },
      type: 0,
      thread_count: 3,
      action:1,
      target:2,
    },
    editedIndex: -1,
    dialog_form: false,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    city_info: city_info,
    district_info: [],
    lbkeys: [],
    lbkeys_count: 0,
    search_options: {},
    search_headers: [],

    auto_into_pool: true,
    type_items: [
      {
        text: vm.$i18n.t("System"),
        value: 0,
      },
      {
        text: vm.$i18n.t("Customer"),
        value: 1,
      },
    ],
    spider_actions: action_type,
    change_types:change_types,

    lbtype_option:lbtype_option,

  }),
  watch: {
    options: {
      handler() {
        this.getSchedule();
      },
    },
  },
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("schedule");
    },
  },
  methods: {
    getSchedule() {
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/schedules/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          action: 1,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data)
          currentObj.items = response.data["results"];
          currentObj.totalItems = response.data["count"];
          // console.log(currentObj.items);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getDistrictsApi() {
      // this.region_info = [];
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.editedItem.car.city,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log("get districts");
          // console.log(response.data);
          currentObj.district_info = response.data;
          console.log("district_info " + currentObj.district_info);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },

    setResult(data) {
      const info = JSON.parse(JSON.stringify(data));
      this.lbkeys_count = data.count;
      this.lbkeys = data.results;
    },

    postApi() {
      // create schedule account
      const currentObj = this;
      console.log("get city:" + this.editedItem.car.city);
      console.log("get district:" + this.editedItem.car.district);
      const url = process.env.VUE_APP_SERVER_URL + "/backend/schedules/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {},
      };
      let car = this.editedItem.car.city + "_" + this.editedItem.car.district;
      const jsonData = {
        car: car,
        action: 1,
        interval: this.editedItem.car.interval,
        default_system: this.default_system ? 2 : 0,
        type: this.editedItem.type,
        thread_count: this.editedItem.thread_count,
        action: this.editedItem.action,
        target: this.editedItem.target,
        lbtype: this.editedItem.car.lbtype,
      };
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log("create schedule done");
          // console.log(response.data);
          currentObj.getSchedule();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    createItem() {
      this.action = "create";
    },
    viewItem(item) {
      // this.action = "view";
      // this.editedIndex = item.id;
      // this.editedItem = JSON.parse(JSON.stringify(item));
      // this.dialog_form = true;
    },
    editItem(item) {
      // this.action = "edit";
      // this.editedIndex = item.id;
      // this.editedItem = JSON.parse(JSON.stringify(item));
      // this.dialog_form = true;
    },
    deleteItem(item){
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/schedules/"+item.id+"/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      if (confirm("sure delete schedule "+item.id+" ?")){
        this.axios
        .delete(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getSchedule();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
      }
    },
    startItem(item) {
      console.log("call start schedule api:" + item.id);
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/schedules/" +
        item.id +
        "/startSchedule/";
      console.log("start schedule:" + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log("start schedule result ");
          currentObj.getSchedule();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    close() {
      this.dialog_form = false;
      setTimeout(() => {
        // this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        // this.region_info = [];
        // this.editedIndex = -1;
      }, 300);
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.patchApi(this.editedIndex);
        this.close();
      } else {
        this.postApi();
        this.close();
      }
    },
    changeAction(item) {
      console.log("change action:"+item.id + "->" + item.action);
    },
  },
  mounted() {
    // this.getSchedule();
  },
  watch: {
    options: {
      handler() {
        this.getSchedule();
      },
    },
  },
};
</script>