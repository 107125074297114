<template>
  <v-row justify="center">
    <v-dialog
      v-model="isShow"
      max-width="300"
      @click:outside="onClose"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title class="justify-center"> {{ title }}</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onConfirm"> 確定 </v-btn>
          <v-btn color="primary" @click="onClose"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    message: {
      type: String,
      default: "",
    },
    selectItem: {
      type: Object,
      default: () => {},
    },
    selectAction: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onConfirm() {
      this.$emit("onConfirm", {
        item: this.selectItem,
        action: this.selectAction,
      });
    },
  },
};
</script>
