<template>
  <v-dialog v-model="dialog_form" max-width="60%" @click:outside="close">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="createItem"
            >
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("schedule-create-upload") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="9">
                    <v-file-input
                        truncate-length="30"
                        @change="selectFile"
                    ></v-file-input>

                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      :label="$t('thread_count')"
                      v-model="editedItem.thread_count"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-select
                      :label="$t('action')"
                      :items="spider_actions"
                      v-model="editedItem.mission_action"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('change_type')"
                      :items="change_types"
                      v-model="editedItem.change_type"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :label="$t('lbtype')"
                      :items="lbtype_option"
                      v-model="editedItem.lbtype"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-if="action != 'view'">
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1" text @click="save">
                {{ $t("create_schedule") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--[end] dialog for search lbkey and add schedule -->
      </v-col>
</template>
<script>
import { mapState } from "vuex";
import {
  city_info,
  action_type,
  change_types,
  lbtype_option,
} from "@/definition.js";
export default {
  data: (vm) => ({
    default_system: false,

    editedItem: {
      file: "",
      type: 0,
      thread_count: 2,
      mission_action: 0,
      change_type: 1,
      lbtype: "L",
    },
    defaultItem: {
      file: "",
      type: 0,
      thread_count: 2,
      mission_action: 1,
      change_type: 1,
      lbtype: "L",
    },
    editedIndex: -1,
    dialog_form: false,
    action: "",
    auto_into_pool: true,
    type_items: [
      {
        text: vm.$i18n.t("System"),
        value: 0,
      },
      {
        text: vm.$i18n.t("Customer"),
        value: 1,
      },
    ],
    spider_actions: action_type,
    change_types: change_types,

    lbtype_option: lbtype_option,
  }),

  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("schedule");
    },
  },
  methods: {
    selectFile(file) {
      console.log("select file " + file);
      this.editedItem.file = file;
    },
    postApi() {
      // create schedule account
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/schedules/import_xlxs/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {},
      };
      let formData = new FormData();
      formData.append("file", this.editedItem.file);
      formData.append("type", this.editedItem.type);
      formData.append("thread_count", this.editedItem.thread_count);
      formData.append("mission_action", this.editedItem.mission_action);
      formData.append("mission_change_type", this.editedItem.change_type);

      // const jsonData = {
      //   // default_system: this.default_system ? 2 : 0,
      //   type: this.editedItem.type,
      //   thread_count: this.editedItem.thread_count,
      //   mission_action: this.editedItem.action,
      //   mission_change_type: this.editedItem.change_type,
      //   lbtype: this.editedItem.lbtype,
      //   file: this.editedItem.file,
      // };
      this.axios
        .post(
          url,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          config
        )
        .then(function (response) {
          console.log("create schedule done:" + response.data);
          // console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    createItem() {
      this.action = "create";
    },
    close() {
      this.dialog_form = false;
      setTimeout(() => {
        // this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        // this.region_info = [];
        // this.editedIndex = -1;
      }, 300);
    },
    save() {
      console.log(this.editedItem);

      this.postApi();
      this.close();
    },
    changeAction(item) {
      console.log("change action:" + item.id + "->" + item.action);
    },
  },
  mounted() {
    // this.getSchedule();
  },
};
</script>
