var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"task-pool-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.poolTitle}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"dense":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('tr',[_c('td',[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.filter_toggle),callback:function ($$v) {_vm.filter_toggle=$$v},expression:"filter_toggle"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(null)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-collapse-all-outline")])],1)]}}])},[_c('span',[_vm._v("All")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.List)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v("查詢")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.Transcript)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-script-outline")])],1)]}}])},[_c('span',[_vm._v("謄本")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.FullTranscript)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-script-text")])],1)]}}])},[_c('span',[_vm._v("完整謄本")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.Mixed)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pot-mix-outline")])],1)]}}])},[_c('span',[_vm._v("混合")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.CopOwnerDiff)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-up-down-bold-outline")])],1)]}}])},[_c('span',[_vm._v("逢甲所有")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onActionChange(_vm.action_type_enum.CopCreditorDiff)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-toothbrush-paste")])],1)]}}])},[_c('span',[_vm._v("逢甲他項")])])],1)],1),_c('td',[_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.filter_status),callback:function ($$v) {_vm.filter_status=$$v},expression:"filter_status"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onStatusChange(null)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-run")])],1)]}}])},[_c('span',[_vm._v("未完成")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onStatusChange(_vm.task_status_enum.Packed)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-package")])],1)]}}])},[_c('span',[_vm._v("Packed")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onStatusChange(_vm.task_status_enum.Processing)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-airplane")])],1)]}}])},[_c('span',[_vm._v("進行中")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.onStatusChange(_vm.task_status_enum.Done)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("完成")])])],1)],1)])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},on:{"save":function($event){return _vm.updateStatus(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',{attrs:{"align":"baseline"}},[_c('v-select',{attrs:{"label":_vm.$t('status'),"items":_vm.status_items,"large":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("getText")(item.status,_vm.status_items))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.action,_vm.spider_actions))))]}},{key:"item.option",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("getText")(item.option,_vm.spider_options)))]}},{key:"item.system",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.system,_vm.spider_system))))]}},{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.create_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.update_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.update_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.rules",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getPartial(item.rules, item.regno_num)))]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":20},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }