var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"mission-tables","fluid":"","tag":"section"}},[_c('ConfirmDialog',{attrs:{"isShow":_vm.confirmData.isConfirm,"title":_vm.confirmData.confirm_title,"message":_vm.confirmData.confirm_msg,"selectItem":_vm.confirmData.selectItem,"selectAction":_vm.confirmData.selectAction},on:{"onClose":_vm.onClose,"onConfirm":_vm.onConfirm}}),_c('v-row',[(!_vm.read_only)?_c('v-col',[_c('MissionRestartLbkey')],1):_vm._e(),(!_vm.read_only)?_c('v-col',{staticClass:"text-right"},[_c('MissionCreateDialog',{attrs:{"create_form":_vm.create_form}})],1):_vm._e()],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"mission"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"multi-sort":"","show-expand":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn-toggle',{attrs:{"color":"primary"},model:{value:(_vm.filter_toggle),callback:function ($$v) {_vm.filter_toggle=$$v},expression:"filter_toggle"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.doFilter('0')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tools")])],1)]}}])},[_c('span',[_vm._v("Running")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.doFilter('1')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-collapse-all-outline")])],1)]}}])},[_c('span',[_vm._v("All")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.doActionFilter(_vm.action_type_enum.List)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v("List")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.doActionFilter(_vm.action_type_enum.Transcript)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-script-outline")])],1)]}}])},[_c('span',[_vm._v("Transcript")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"click":function($event){return _vm.doActionFilter(_vm.action_type_enum.Mixed)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pot-mix-outline")])],1)]}}])},[_c('span',[_vm._v("Mixed")])])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":12}},[_c('task',{attrs:{"mission_id":item.id}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getText")(item.status,_vm.status_items))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":""},on:{"save":function($event){return _vm.changeMissionAction(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',{attrs:{"align":"baseline"}},[_c('v-select',{attrs:{"label":_vm.$t('action'),"items":_vm.lb_actions,"item-text":function (item) { return _vm.$t(item.text); },"large":""},model:{value:(item.action),callback:function ($$v) {_vm.$set(item, "action", $$v)},expression:"item.action"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(item.action,_vm.lb_actions)))+" ")])]}},{key:"item.change_type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.change_type,_vm.change_types))))]}},{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.create_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.update_time",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.update_time,"YYYY-MM-DD HH:mm:ss")))]}},{key:"item.cdr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.cdr))]}},{key:"item.done_rate",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.isCompleted(item)},[_vm._v(" "+_vm._s(item.task_done_num)+"/"+_vm._s(item.task_num)+" ("+_vm._s(parseFloat( (item.task_done_num / item.task_num || 0) * 100 ).toFixed(2) + "%")+") ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.inPool(item)}}},on),[_vm._v("mdi-pool")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("in-pool")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(
              item.action == _vm.action_type_enum.List && item.change_type == 1
            )?{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.forceCopOwnerDiff(item)}}},on),[_vm._v("mdi-application-edit")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("cop-owner-diff")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(
              (item.action == _vm.action_type_enum.List ||
                item.action == _vm.action_type_enum.Mixed) &&
              item.change_type == 2
            )?{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.forceCopCreditorDiff(item)}}},on),[_vm._v("mdi-access-point-network")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("cop-creditor-diff")))])])]}}])}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":20,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }