 <template>
  <v-dialog v-model="create_form" max-width="60%" @click:outside="close">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" v-on="on">
        <v-icon left>mdi-account-plus</v-icon>
        {{ $t("mission-create") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("create") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="2">
              <v-select
                :label="$t('city')"
                :items="city_info"
                v-model="editedItem.car.city"
                v-on:change="getDistrictsApi"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                :label="$t('district')"
                :items="district_info"
                v-model="editedItem.car.district"
                @change="getRegionApi"
                :item-text="(item) => item.name + '(' + item.code + ')'"
                item-value="code"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                :label="$t('region')"
                :items="region_info"
                v-model="editedItem.car.region"
                :item-text="getRegionDisplay"
                item-value="code"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                :label="$t('lbtype')"
                :items="lbtype_option"
                v-model="editedItem.car.lbtype"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                :label="$t('action')"
                :items="spider_actions"
                v-model="editedItem.action"
              ></v-select>
            </v-col>

            <v-col cols="3">
              <v-select
                :label="$t('change_type')"
                :items="change_types"
                v-model="editedItem.change_type"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                :label="$t('interval')"
                :items="interval"
                v-model="editedItem.car.interval"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                :label="$t('exclude has building')"
                :items="exclude_has_building_option"
                v-model="editedItem.exclude_has_building"
              ></v-select>
            </v-col>
            <v-col cols="1">
              <v-card-actions>
                <v-btn color="primary" @click="serach_keys">
                  <v-icon>mdi-file-find</v-icon>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-data-table
            :headers="search_headers"
            :options.sync="search_options"
            :items="lbkeys"
            :server-items-length="lbkeys_count"
            :loading="loading"
            multi-sort
          >
            <template v-slot:item.create_time="{ item }">{{
              item.create_time | moment("YYYY-MM-DD HH:mm:ss")
            }}</template>
            <template v-slot:item.update_time="{ item }">{{
              item.update_time | moment("YYYY-MM-DD HH:mm:ss")
            }}</template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="auto_into_pool"
          label="Auto into pool"
        ></v-checkbox>
        <v-checkbox v-model="default_system" label="Use EPLand"></v-checkbox>
        <v-btn color="blue darken-1" text @click="createMission">
          {{ $t("create_mission") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="close">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { change_types } from "@/definition.js";
import CDRBase from "@/views/dashboard/components/core/CDRBase.vue";
export default {
  props: {},
  data: (vm) => ({
    default_system: true,
    create_form: false,
    auto_into_pool: true,
    loading: false,
    lbkeys: [],
    lbkeys_count: 0,
    change_types: change_types,

    search_headers: [
      {
        sortable: false,
        text: vm.$i18n.t("city_name"),
        value: "city.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("district_name"),
        value: "district.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region_name"),
        value: "region.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owners_num"),
        value: "owners_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("creditors_num"),
        value: "creditors_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
    ],
    search_options: {},
    exclude_has_building_option: [
      {
        text: vm.$i18n.t("yes"),
        value: true,
      },
      {
        text: vm.$i18n.t("no"),
        value: false,
      },
    ],
    interval: [
      {
        text: vm.$i18n.t("latest"),
        value: 0,
      },
      {
        text: vm.$i18n.t("3 days"),
        value: 3,
      },
      {
        text: vm.$i18n.t("Weeks"),
        value: 7,
      },
    ],
  }),
  mixins: [CDRBase],
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    close() {
      this.create_form = false;
    },
    serach_keys() {
      if (
        this.editedItem.car.district == null ||
        this.editedItem.car.district == "" ||
        this.editedItem.car.region == null ||
        this.editedItem.car.region == ""
      ) {
        console.log("not select district");
        return;
      } else {
        console.log(
          "this.editedItem.car.district" + this.editedItem.car.district
        );
      }
      const currentObj = this;
      let lbtype = "lands";
      if (this.editedItem.car.lbtype == 2) {
        lbtype = "buildings";
      }
      const url = process.env.VUE_APP_SERVER_URL + "/backend/" + lbtype + "/";

      const { sortBy, sortDesc, page, itemsPerPage } = this.search_options;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };

      config.params["city__code"] = this.editedItem.car.city;
      config.params["district__code"] = this.editedItem.car.district;
      if (this.editedItem.car.region) {
        config.params["region__code"] = this.editedItem.car.region;
      } else {
        console.log("no region");
      }
      if (this.editedItem.exclude_has_building == true) {
        config.params["exclude_has_building"] = true;
      }
      config.params["query"] =
        "{ id, city, district, region, lbkey,update_time,owners_num,creditors_num }";

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      const info = JSON.parse(JSON.stringify(data));
      this.lbkeys_count = data.count;
      this.lbkeys = data.results;
    },
    createMission() {
      this.postApi();
      this.close();
    },
    postApi() {
      const currentObj = this;
      // console.log("get city:" + this.editedItem.car.city);
      // console.log("get district:" + this.editedItem.car.district);
      // console.log("get region:" + this.editedItem.car.region);
      const url = process.env.VUE_APP_SERVER_URL + "/backend/missions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {},
      };
      let cdr =
        this.editedItem.car.city +
        "_" +
        this.editedItem.car.district +
        "_" +
        this.editedItem.car.region;
      const jsonData = {
        cdr: cdr,
        // action: 1,
        interval: this.editedItem.car.interval,
        inpool: this.auto_into_pool,
        default_system: this.default_system ? 2 : 0,
        lbtype: this.editedItem.car.lbtype,
        action: this.editedItem.action,
        target: this.editedItem.target,
      };
      console.log("jsonData " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.getMission();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
  getMission() {
    console.log("get mission " + this.read_only);
    const currentObj = this;
    currentObj.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage } = this.options;
    const url = process.env.VUE_APP_SERVER_URL + "/backend/missions/";
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + this.token,
      },
      params: {
        page: page,
        page_size: itemsPerPage,
        action__lte: 1,
      },
    };
    if (!this.$common_util.isEmpty(this.schedule_id)) {
      config.params["schedule__id"] = this.schedule_id;
    }

    this.axios
      .get(url, config)
      .then(function (response) {
        console.log(response.data);
        currentObj.loading = false;
        currentObj.items = response.data["results"];
        currentObj.totalItems = response.data["count"];
        // console.log(currentObj.items);
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
      });
  },
};
</script>
