<template>
  <v-container id="task-pool-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="poolTitle"
      class="px-5 py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :loading="loading"
        dense
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <tr>
            <!-- action -->
            <td>
              <v-btn-toggle v-model="filter_toggle" color="primary">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(null)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-collapse-all-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>All</span>
                </v-tooltip>
                <!-- List Only -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.List)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                  </template>
                  <span>查詢</span>
                </v-tooltip>
                <!-- TP -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.Transcript)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-script-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>謄本</span>
                </v-tooltip>
                <!-- TP -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.FullTranscript)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-script-text</v-icon>
                    </v-btn>
                  </template>
                  <span>完整謄本</span>
                </v-tooltip>
                <!-- Change -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.Mixed)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pot-mix-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>混合</span>
                </v-tooltip>
                <!-- cop owner-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.CopOwnerDiff)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-arrow-up-down-bold-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>逢甲所有</span>
                </v-tooltip>
                <!-- cop creditor -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onActionChange(action_type_enum.CopCreditorDiff)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-toothbrush-paste</v-icon>
                    </v-btn>
                  </template>
                  <span>逢甲他項</span>
                </v-tooltip>
              </v-btn-toggle>
            </td>
            <td>
              <!-- status -->
              <v-btn-toggle v-model="filter_status" color="primary">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onStatusChange(null)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-run</v-icon>
                    </v-btn>
                  </template>
                  <span>未完成</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onStatusChange(task_status_enum.Packed)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-package</v-icon>
                    </v-btn>
                  </template>
                  <span>Packed</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onStatusChange(task_status_enum.Processing)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-airplane</v-icon>
                    </v-btn>
                  </template>
                  <span>進行中</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="onStatusChange(task_status_enum.Done)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>完成</span>
                </v-tooltip>
              </v-btn-toggle>
            </td>
          </tr>
        </template>
        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template dense v-slot:item.action="{ item }">{{
          item.action | getText(spider_actions) | Translate
        }}</template>
        <template v-slot:item.option="{ item }">{{
          item.option | getText(spider_options)
        }}</template>
        <template v-slot:item.system="{ item }">{{
          item.system | getText(spider_system) | Translate
        }}</template>
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.rules="{ item }">{{
          getPartial(item.rules, item.regno_num)
        }}</template>
      </v-data-table>
    </base-material-card>
    <v-pagination
      v-model="page"
      :length="pageCount"
      :total-visible="20"
    ></v-pagination>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import {
  city_info,
  status_items,
  oc_option,
  systemOption,
  action_type,
  action_type_enum,
  task_status_enum,
  action_type_enum_str,
} from "@/definition.js";

export default {
  props: {
    schedule_id: {
      type: Number,
      default: null,
    },
  },
  data: (vm) => ({
    filter_toggle: 0,
    filter_status: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    selected: [],
    info_dialog: false,
    dialog_form: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("city"),
        value: "city",
      },
      {
        sortable: false,
        text: vm.$i18n.t("district"),
        value: "district",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region"),
        value: "region",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbkey"),
        value: "lbkey",
      },
      {
        sortable: false,
        text: vm.$i18n.t("system"),
        value: "system",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action"),
        value: "action",
      },
      {
        sortable: false,
        text: vm.$i18n.t("option"),
        value: "option",
      },
      {
        sortable: false,
        text: vm.$i18n.t("rules"),
        value: "rules",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
    ],
    city_info: city_info,
    district_info: [],
    region_info: [],
    status_items: status_items,
    spider_actions: action_type,
    spider_options: oc_option,
    spider_system: systemOption,
    task_status_enum: task_status_enum,
    action_type_enum: action_type_enum,
    action_type_enum_str: action_type_enum_str,
    action: action_type.List,
    status: null,
  }),
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  computed: {
    ...mapState(["token"]),
    poolTitle() {
      console.log("this.action " + this.action);
      if (this.action === null || this.action === undefined) {
        return `任務池 全部 數量:${this.totalItems}`;
      }
      return `任務池  ${this.$common_util.getText(
        this.action,
        this.action_type_enum_str
      )} 數量:${this.totalItems}`;
    },
  },
  methods: {
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/taskpools/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          action: this.action,
        },
      };
      if (!this.$common_util.isEmpty(this.schedule_id)) {
        config.params["schedule__id"] = this.schedule_id;
      }

      if (this.$common_util.isEmpty(this.status)) {
        config.params["status__lt"] = 4;
      } else {
        config.params["status"] = this.status;
      }

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    postApi() {},
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    onActionChange(action) {
      this.action = action;
      this.getApi();
    },
    onStatusChange(status) {
      this.status = status;
      this.getApi();
    },
    getPartial(item, regno_num) {
      if (item != null) {
        let data = "";
        if (regno_num != null && regno_num != undefined) {
          data = `(${regno_num}) ${item.substring(0, 30)}`;
        }
        if (item.length > 30) {
          data += "....";
        }
        return data;
      }
      return item;
    },
  },

  mounted() {
    console.log("schedule id " + this.schedule_id);
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
};
</script>
