<template></template>
<script>
import {
  city_info,
  status_items,
  lbtype_option,
  radioOption,
  change_types,
} from "@/definition.js";
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    editedItem: {
      car: {
        city: "",
        district: "",
        region: "0344",
        interval: 7,
        lbtype: "L",
      },
      action: 0,
      change_type: 2,
      exclude_has_building: false,
    },
    change_typess: change_types,
    lbtype_option: lbtype_option,
    spider_actions: radioOption,
    city_info: city_info,
    district_info: [],
    region_info: [],
    debug: false,
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    getDistrictsApi() {
      this.region_info = [];
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.editedItem.car.city,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          if (currentObj.debug) {
            console.log(response.data);
          }
          currentObj.district_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionDisplay(item) {
      return item.name + "(" + item.code + ") [" + item.land_keys + "]";
    },
    getDistrictsApi() {
      this.region_info = [];
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/districts/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.editedItem.car.city,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          if (currentObj.debug) {
            console.log(response.data);
          }
          currentObj.district_info = response.data;
          // console.log("district_info " + currentObj.district_info);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getRegionApi() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/regions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city__code: this.editedItem.car.city,
          district__code: this.editedItem.car.district,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          if (currentObj.debug) {
            console.log(response.data);
          }
          currentObj.region_info = response.data;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
