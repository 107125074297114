<template>
  <v-dialog
    v-model="dialog_show"
    max-width="600px"
    @click:outside="onClose"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">任務 ({{ origin }})</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="2">
              <v-text-field label="列表" disabled />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="group"
                v-model="group"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="count"
                v-model="count"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="list"
                readonly
                v-model="accounts_list_str"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field label="謄本" disabled />
            </v-col>
            <v-col cols="6">
              <v-text-field label="tp" v-model="tp_list"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="updateAccounts">
          {{ $t("update") }}
        </v-btn>
        <v-btn color="blue" @click="onClose(null)">
          {{ $t("close") }}
        </v-btn>
        <v-btn color="secondary" @click="resetSetting">
          {{ $t("reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    dialog_show: false,
    origin: 0,
    list: null,
    tp: null,
  },
  data: function () {
    return {
      account_group: null,
      account_count: 0,
      accounts_tp: null,
    };
  },
  computed: {
    ...mapState(["token"]),
    accounts_list_str: function () {
      if (this.account_count != null) {
        const arr = [];
        for (let i = 0; i < this.account_count; i++) {
          arr.push(this.account_group);
        }
        const list_str = arr.join(",");
        return list_str;
      }
      return "";
    },
    group: {
      get() {
        if (this.list != null) {
          this.account_group = this.list.split(",")[0];
          return this.list.split(",")[0];
        }
        return "";
      },
      set(val) {
        this.account_group = val;
      },
    },
    count: {
      get() {
        if (this.list != null) {
          this.account_count = this.list.split(",").length;
          return this.list.split(",").length;
        }
        return 0;
      },
      set(val) {
        this.account_count = val;
      },
    },
    tp_list: {
      get() {
        if (this.tp != null) {
          this.accounts_tp = this.tp;
          return this.tp;
        }
        return 0;
      },
      set(val) {
        this.accounts_tp = val;
      },
    },
  },
  methods: {
    getApi() {},
    onClose(item) {
      this.reset();
      this.$emit("onClose", item);
    },

    reset() {
      this.account_group = null;
      this.account_count = 0;
    },
    updateAccounts() {
      // TODO check the account tp format
      console.log("update list:", this.accounts_list_str);
      console.log("update tp:", this.accounts_tp);
      this.patchListAndTP(this.accounts_list_str, this.accounts_tp);

      this.onClose(this.account_group);
    },
    resetSetting() {
      this.patchListAndTP(null, null);
      this.onClose(this.account_group);
    },
    patchListAndTP(list_str, tp_str) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/schedules/" +
        this.origin +
        "/";
      let jsonData = {
        accounts: list_str,
        accounts_tp: tp_str,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json ", jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>